.page-header {

  &__wrapper {

    @include desktop {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {

    @include desktop {
      width: span(4);
    }
  }

  &__content {

    @include desktop {
      width: span(8);
    }
  }
}
