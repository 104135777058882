// General text utilities.
.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.t-bold {
  font-weight: 500;
}

.t-upper {
  text-transform: uppercase;
}

.t-xl {
  font-size: 2.2rem;

  @include tablet {
    font-size: 2.4rem;
  }

  @include desktop {
    font-size: 3rem;
  }
}

.t-l {
  font-size: 1.8rem;

  @include tablet {
    font-size: 2rem;
  }
}

.t-m {
  font-size: 1.7rem;

  @include tablet {
    font-size: 1.8rem;
  }
}

.t-s {
  font-size: 1.6rem;
}

.t-xs {
  font-size: 1.4rem;
}
