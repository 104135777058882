.pagination {
  border-top: 1px solid $separator;

  @include mobile-only {
    text-align: center;
    padding: 2rem 0;
  }

  a {
    text-decoration: none;
  }

  &__wrapper {
    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__prev,
  &__next {
    color: $grey;
    font-size: 1.6rem;
    font-weight: 500;

    a {
      color: $pink;
    }
  }

  &__prev {

    a, span {
      display: inline-block;
      padding-left: 50px;
      background: url('../assets/images/arrow-prev-pink.svg') left center no-repeat;
    }

    span {
      background: url('../assets/images/arrow-prev-grey.svg') left center no-repeat;
    }
  }

  &__next {

    a, span {
      display: inline-block;
      padding-right: 50px;
      background: url('../assets/images/arrow-next-pink.svg') right center no-repeat;
    }

    span {
      background: url('../assets/images/arrow-next-grey.svg') right center no-repeat;
    }
  }

  &__pages {
    margin: 0;
    padding: 1rem 0;
    list-style: none;
    font-size: 3rem;

    @include tablet {
      padding: 8rem 10rem;
      border-left: 1px solid $separator;
      border-right: 1px solid $separator;
    }

    li {
      display: inline-block;
      margin: 0 .5rem;
      font-weight: 500;
    }

    .current {
      color: $pink;
    }

    a {
      @include link-two-colors($black, $pink);
    }
  }
}
