.cover {
  position: relative;
  overflow: hidden;
  z-index: 20;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 10;
  }

  &__container {
    position: relative;
    z-index: 42;
  }
}