.c-primary {
  color: $turquoise;
}

.c-secondary {
  color: $pink;

  .th-dark & {
    color: $orange;
  }
}
