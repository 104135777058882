.card {

  @include tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
