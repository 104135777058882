.browserupgrade {
  padding: 20px;
  background-color: #be0707;
  color: #fff;
  text-align: center;

  a {
    color: #fff !important;
  }
}
