.nav-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .title {
    margin-right: 40px;
  }

  select {
    margin-top: 20px;

    @include tablet {
      margin-top: 0;
      width: 300px;
    }
  }
}
