// Visibility
.hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.no-js {
  .hidden--no-js {
    display: none !important;
  }
}

.hidden-mobile {
  // display: none !important;
  @include mobile-only {
    display: none !important;
  }
}

.hidden-tablet {
  // display: none !important;
  @include tablet-only {
    display: none !important;
  }
}

.hidden-desktop {
  display: block !important;
  @include desktop {
    display: none !important;
  }
}
