html {
  // @include font-smoothing;
  font-family: 'Heebo', sans-serif;
  font-size: 62.5%; // = 10px
  line-height: 1.5;
}

body {
  font-size: 1.6rem;
  font-weight: 300;
  color: $black;

  @include tablet {
    font-size: 1.8rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  margin-top: 0;
  font-weight: 500;
}

.title,
.subtitle {
  font-weight: 500;
  line-height: 1.25;
  color: inherit;
}

.title {
  font-size: 3.2rem;
  margin-bottom: 3.2rem;

  @include tablet {
    font-size: 3.8rem;
    margin-bottom: 3.8rem;
  }

  @include desktop {
    font-size: 5rem;
    margin-bottom: 5rem;
  }
}

.subtitle {
  font-size: 2.6rem;
  margin-bottom: 2.6rem;

  @include tablet {
    font-size: 2.8rem;
    margin-bottom: 2.8rem;
  }

  @include desktop {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
}

p {
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  @include link-two-colors($black, $turquoise);
}
