.button,
input[type="submit"],
input[type="button"] {
  @include font-smoothing;
  display: inline-block;
  background: $turquoise;
  padding: 10px 20px;
  font-size: 1.8rem;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  color: $white !important;
  font-size: 2rem;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 40px 50px 60px 0 rgba(0,0,0,0.07);
  transform: translateY(0);
  transition: all .2s;
  width: 100%;

  @include tablet {
    padding: 16px 30px;
    width: auto;
  }

  &:hover {
    box-shadow: 15px 20px 30px 0 rgba(0,0,0,0.07);
    transform: translateY(5px);
  }

  &:active {
    background-color: darken($turquoise, 10%);
    box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.07);
    transform: translateY(8px);
  }

  .th-dark & {
    color: $black !important;
  }

  .th-rounded & {
    border-radius: 31px;
  }
}

.button {

  &--i {
    padding-right: 65px;
    background-repeat: no-repeat;
    background-position: top 50% right 15px;

    @include tablet {
      background-position: top 50% right 20px;
    }
  }

  &--i-internal-link {
    background-image: url("../assets/images/internal-link.svg");
    .th-dark & {
      background-image: url("../assets/images/internal-link--black.svg");
    }
  }

  &--i-external-link {
    background-image: url("../assets/images/external-link.svg");
    .th-dark & {
      background-image: url("../assets/images/external-link--black.svg");
    }
  }

  &--i-file {
    background-image: url("../assets/images/file.svg");
    .th-dark & {
      background-image: url("../assets/images/file--black.svg");
    }
  }

  &--i-email {
    background-image: url("../assets/images/email.svg");
    .th-dark & {
      background-image: url("../assets/images/email--black.svg");
    }
  }

  &--i-phone {
    background-image: url("../assets/images/phone.svg");
    .th-dark & {
      background-image: url("../assets/images/phone--black.svg");
    }
  }

  &--fullwidth {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  &--small {
    font-size: 1.6rem;
    padding: 8px 15px;

    .th-rounded & {
      border-radius: 21px;
    }
  }

  &--c-secondary {
    background-color: $pink;

    .th-dark & {
      background-color: $orange;
      color: $black !important;
    }

    &:active {
      background-color: darken($pink, 10%);

      .th-dark & {
        background-color: darken($orange, 10%);
      }
    }
  }
}
