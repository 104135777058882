.team {

  &__members {

    @include tablet {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__member {
    margin-bottom: 30px;

    @include tablet {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0;
      width: span(2);
    }

    @include desktop {
      width: span(3);
      margin: 0 gutter()/2;

      &:nth-child(4n-3) {
        margin-left: 0;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  &__member-image {
    display: block;
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto;
  }

  &__member-links {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0 10px;
    }

    .th-dark & {
      svg path {
        fill: $orange;
      }
    }
  }
}
