.post-header {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }

  @include desktop {
    align-items: flex-end;
  }

  &__thumbnail {

    @include tablet {
      width: span(2);
    }

    @include desktop {
      width: span(5);
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 30px;

      @include tablet {
        margin-bottom: 0;
      }
    }
  }

  &__content {

    @include tablet {
      width: span(4);
    }

    @include desktop {
      width: span(7);
    }
  }
}
