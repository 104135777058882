.text {

  &__introduction {
    margin-bottom: 26px;

    &--2col,
    &--3col {

      @include tablet {
        width: span(3);
        margin-bottom: 0;
      }

      @include desktop {
        width: span(4);
      }
    }
  }

  &__content {

    &--2col,
    &--3col {

      @include tablet {
        width: span(3);
      }

      @include desktop {
        width: span(8);
      }
    }

    &--3col {

      @include desktop {
        column-count: 2;
        column-gap: gutter(of 8);
      }
    }
  }
}
