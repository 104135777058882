.site-footer {
  background: $turquoise;
  color: $white;

  .th-dark & {
    color: $black;
  }

  &__left {
    margin-bottom: 50px;

    @include tablet {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: span(3);
      margin-bottom: 0;
    }

    @include desktop {
      width: span(4);
    }
  }

  &__right {

    @include tablet {
      width: span(3);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
    }

    @include desktop {
      width: span(8);
    }
  }

  &__identity {
    margin-bottom: 50px;

    @include tablet {
      margin-bottom: 0;
    }
  }

  &__copyright {
    font-size: 1.4rem;
  }

  &__logo {
    display: inline-block;
    margin-bottom: 10px;

    svg {
      width: 123px;
      height: auto;

      path {
        fill: $white;

        .th-dark & {
          fill: $black;
        }
      }
    }
  }
}
