// Custom colors
$black: #000;
$white: #fff;
$grey: #9a9a9a;
$darkgrey: #1D1D1D;
$blue: #003866;
$turquoise: #52bfd3;
$pink: #ff5269;
$yellow: #fff7d3;
$red: #e60d2b;
$orange: #FFCB8F;
$separator: #d8d8d8;

