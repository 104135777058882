input[type='text'],
input[type='number'],
input[type='email'],
input[type='phone'],
textarea,
select {
  border: 1px solid #e0e0e0;
  padding: 12px 10px;
  font-family: 'Heebo', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  transition: all .2s;
  background: transparent;

  .th-dark & {
    border-color: $grey;
    color: $white;
  }

  &:focus {
    border: 1px solid $turquoise;
    box-shadow: 0 0 7px 0 rgba(82,191,211,0.68);
  }
}

input[type='radio'],
input[type='checkbox'] {
  display: none;

  &+label {
    position: relative;
    padding-left: 34px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background: transparent;
      border: 1px solid #ccc;;
      transition: all .3s;

      .th-dark & {
        border-color: $grey;
      }
    }
  }

  &:checked {

    &+label {

      &:before {
        background: $turquoise;
        border-color: $turquoise;
      }

      &:after {
        transform: scale(1);
      }
    }
  }
}

input[type='radio'] {

  &+label {

    &:before {
      border-radius: 50%;
    }

    &:after {
      border-radius: 50%;
      content: "";
      display: block;
      position: absolute;
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      background: $white;
      transform-origin: center;
      transform: scale(0);
      transition: all .2s;
    }
  }
}

input[type='checkbox'] {

  &+label {

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 1px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
    }
  }

  &:checked {

    &+label {

      &:after {
        background-image: url("../assets/images/icon-check.svg");
      }
    }
  }
}

select {
  padding: 12px 10px;
  font-family: 'Heebo', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  background-image: url("../assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: top 22px right 20px;

  .th-dark & {
    color: $white;
  }

  &::-ms-expand {
    display: none;
  }
}

.form {
  &__field-wrapper {
    display: block;
    margin-bottom: 20px;
  }

  &__field-label {
    display: block;
    font-size: 1.3rem;
    font-weight: 500;
    color: #5f5f5f;
    margin-bottom: 15px;

    .th-dark & {
      color: $white;
    }
  }
}

.gform_wrapper {
  font-size: 1.8rem;
  font-weight: 300;

  .validation_error {
    background: #FFE6E6;
    padding: 20px;
    margin-bottom: 40px;
    font-size: 1.6rem;
    color: $black;
  }
}

.gform_fields {
  margin: 0;
  padding: 0;

  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.gfield {
  list-style: none;
  margin-bottom: 40px;

  @include tablet {
    width: span(3 of 6);
  }

  @include desktop {
    width: span(4 of 8);
  }

  .gfield_label {
    display: block;
    font-size: 1.3rem;
    font-weight: 500;
    color: #5f5f5f;
    margin-bottom: 12px;

    .th-dark & {
      color: $white;
    }
  }

  &--textarea {

    @include tablet {
      width: 100%;
    }
  }

  &_required {
    color: $red;
    margin-left: 4px;
  }

  &_radio,
  &_checkbox {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .validation_message {
    margin-top: 5px;
    font-size: 1.4rem;
    font-weight: 500;
    color: $red;
  }
}
