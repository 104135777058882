// @see https://jonsuh.com/hamburgers/ for more informations
// Set the $hambuerger-types to only compile the relevant scss file.
$hamburger-types: (slider);

// Settings
// ==================================================
$hamburger-padding-x: 15px;
$hamburger-padding-y: 19px;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: $white;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.15s;
$hamburger-hover-transition-timing-function: linear;
// To use CSS filters as the hover effect instead of opacity,
// set $menu-toggle-hover-use-filter as true and change the value of $menu-toggle-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);

@import 'hamburgers/_sass/hamburgers/hamburgers';

.menu-toggle {
  background: $turquoise;
  position: absolute;
  top: 80px;
  right: 20px;
  z-index: 100;
  transition: all .3s;
  transition-delay: .2s;

  .th-rounded & {
    border-radius: 50%;
  }

  @include tablet {
    top: 120px;
    right: 30px;
  }

  &.is-active {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    position: fixed;
  }

  .hamburger-box {
    display: block;
  }
}
