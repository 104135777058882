.contact {

  &__map {
    height: 500px;
    background: #ddd;
    margin-bottom: 50px;

    iframe {
      height: 500px !important;
    }
  }

  &__wrapper {

    @include tablet {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @include desktop {
      flex-wrap: nowrap;
    }
  }

  &__org {

    width: 100%;

    @include desktop {
      width: span(4);
    }
  }

  &__address {
    font-style: normal;
    margin-bottom: 30px;

    @include tablet {
      width: span(3);
      margin-bottom: 0;
    }

    @include desktop {
      width: span(4);
      padding-left: span(1 wide);
    }
  }

  &__adr {
    margin-bottom: 20px;
  }

  &__links {

    @include tablet {
      width: span(3);
    }

    @include desktop {
      width: span(4);
    }
  }
}
