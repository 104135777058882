.social-nav {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: block;
    transform: translateY(0);
    transition: all .2s;

    &:hover {
      transform: translateY(3px);
    }
  }

  &--white {

    svg path{
      fill: $white;
    }
  }

  &--black {

    svg path{
      fill: $black;
    }
  }

  &--primary {

    svg path{
      fill: $turquoise;
    }
  }

  &--secondary {

    svg path{
      fill: $pink;

      .th-dark & {
        fill: $orange;
      }
    }
  }
}
