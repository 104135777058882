.columns--3 {
  @include tablet {
    display: flex;

    > * {
      margin-right: gutter();
      width: span(4);
    }
  }
}
