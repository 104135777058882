// Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
