.site-preheader {
  font-size: 1.4rem;
  font-weight: 300;
  background: $blue;
  color: $white;
  height: 40px;
  padding-top: 9px;

  @include tablet {
    padding-top: 0;
  }

  @include desktop {
    height: 60px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &__link {
    padding-top: 10px;

    @include desktop {
      padding-top: 24px;
    }

    img {
      margin-right: 10px;
    }
  }

  &__logo {
    padding-top: 4px;

    @include desktop {
      padding-top: 8px;
    }

    img {
      width: 120px;
      height: auto;

      @include desktop {
        width: 160px;
      }
    }
  }
}
