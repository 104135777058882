// To define rem value simply divide the px values by 10...
$spacers: (
  mobile: (
    xs: 1rem,
    s: 2rem,
    m: 2.5rem,
    l: 3.75rem,
    xl: 5rem
  ),
  tablet: (
    xs: 1.5rem,
    s: 2.5rem,
    m: 5rem,
    l: 7.5rem,
    xl: 9rem
  ),
  desktop: (
    xs: 2rem,
    s: 3rem,
    m: 5rem,
    l: 10rem,
    xl: 16rem
  )
);
