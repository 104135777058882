@import "swiper/dist/css/swiper";

.swiper-container {}
.swiper-wrapper {}
.swiper-slide {}
.swiper-pagination {}
.swiper-button-prev {}
.swiper-button-next {}
.swiper-scrollbar {}

.swiper {
  position: relative;
}

.swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-button-prev,
.swiper-button-next {
  width: 70px;
  height: 70px;
  background: $turquoise;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -35px;
  top: unset;
  left: unset;
  box-shadow: 40px 50px 60px 0 rgba(0,0,0,0.07);
  transform: translateY(0);
  transition: all .2s;

  .th-rounded & {
    border-radius: 50%;
  }

  @include desktop {
    width: 100px;
    height: 100px;
    bottom: -50px;
  }

  &:hover {
    box-shadow: 15px 20px 30px 0 rgba(0,0,0,0.07);
    transform: translateY(5px);
  }

  &:active {
    background-color: darken($turquoise, 10%);
    box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.07);
    transform: translateY(8px);
  }
}

.swiper-button-prev {
  right: 145px;

  @include desktop {
    left: calc(50% + 220px);
  }
}

.swiper-button-next {
  right: 50px;

  @include desktop {
    left: calc(50% + 340px);
  }
}

