.media-item {
  &__image,
  &__video,
  &__embed {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  &__poster {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: opacity 0.3s, visibility 0.3s;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__wrap {
    img {
      width: 100%;
      display: block;
    }
  }

  &__toggle {
    position: absolute;
    z-index: 2;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 40px 50px 60px 0 rgba(0,0,0,0.1);
    appearance: none;
    background-color: $turquoise;
    font-size: 3rem;
    font-weight: 500;
    color: $white;
    width: 150px;
    height: 150px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: all 0.3s;

    .th-rounded & {
      border-radius: 50%;
    }

    @include desktop {
      width: 200px;
      height: 200px;
    }

    &:hover {
      box-shadow: 15px 20px 30px 0 rgba(0,0,0,0.1);
      transform: translate(-50%, calc(-50% + 5px));
    }

    svg {
      margin: 5px 0 0 20px;

      path {
        fill: $white;
      }
    }

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}
