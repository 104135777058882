.comments {
  margin-bottom: 160px;

  &__title {
    position: relative;
    text-align: center;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      width: 100%;
      border-bottom: 1px solid $separator;
      z-index: -1;

      .th-dark & {
        border-color: $grey;
      }
    }

    span {
      display: inline-block;
      background: $white;
      padding: 0 20px;

      .th-dark & {
        background: $darkgrey;
      }
    }
  }

  &__wrapper {

    @include desktop {
      width: span(8);
      margin: 0 auto;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
