.gallery {

  &__images {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__thumbnail {
    display: block;
    width: span(1);
    margin-right: gutter();
    margin-bottom: gutter();

    &:nth-child(2n) {
      margin-right: 0;
    }

    @include tablet {
      width: span(2);
      margin-right: gutter();
      margin-bottom: gutter();

      &:nth-child(2n) {
        margin-right: gutter();
        margin-bottom: gutter();
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @include desktop {
      width: span(3);
      margin-right: gutter();
      margin-bottom: gutter();

      &:nth-child(3n),
      &:nth-child(2n) {
        margin-right: gutter();
        margin-bottom: gutter();
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
