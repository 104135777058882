.m-t--0 {
  margin-top: 0;
}

.m-b--0 {
  margin-bottom: 0;
}

$sizes--mobile: map-get($spacers, mobile);
$sizes--tablet: map-get($spacers, tablet);
$sizes--desktop: map-get($spacers, desktop);

@each $key, $size in $sizes--mobile {
  .m-t--#{$key} {
    margin-top: $size;
  }

  .m-b--#{$key} {
    margin-bottom: $size;
  }
}

@include tablet {
  @each $key, $size in $sizes--tablet {
    $mobile_size: map-get($sizes--mobile, $key);

    @if $size != $mobile_size {
      .m-t--#{$key} {
        margin-top: $size;
      }

      .m-b--#{$key} {
        margin-bottom: $size;
      }
    }
  }
}

@include desktop {
  @each $key, $size in $sizes--desktop {
    $tablet_size: map-get($sizes--tablet, $key);

    @if $size != $tablet_size {
      .m-t--#{$key} {
        margin-top: $size;
      }

      .m-b--#{$key} {
        margin-bottom: $size;
      }
    }
  }
}
