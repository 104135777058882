.nav-main {
  font-size: 1.6rem;
  font-weight: 500;
  position: relative;
  text-align: right;

  @include no-desktop {
    text-align: left;
    position: fixed;
    top: 0;
    right: 46px;
    bottom: 0;
    left: 0;
    padding: 90px 30px 30px 30px;
    background: $white;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
    transform: translateX(-110%);
    transition: all .5s $easeOutQuart;

    .th-dark & {
      background: $black;
      border-right: 1px solid #555;
    }

    .menu-is-open & {
      transform: translateX(0);
    }
  }

  @include tablet-only {
    right: 56px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    @include desktop {
      display: inline-block;
    }
  }

  li {
    display: block;
    margin: 0 0 20px 0;

    @include desktop {
      display: inline-block;
      margin: 0  0 0 30px;
    }
  }

  a {
    text-decoration: none;
    transition: all .2s;
  }

  &__locales {

    @include desktop {
      display: inline-block;
    }

    li {
      text-transform: uppercase;
      display: inline-block;
      margin: 0 20px 0 0;

      @include desktop {
        margin: 0 0 0 20px;
      }
    }
  }

  &__locales-label {
    border-top: 1px solid $separator;
    padding-top: 10px;
    margin-top: 40px;
    margin-bottom: 10px;
    color: $grey;

    @include desktop {
      display: none;
    }

    .th-dark & {
      border-top: 1px solid #555;
    }
  }

  .bouton a {
    @extend .button;
    font-size: 1.6rem;
    padding: 9px 20px;
  }

  &--header {
    a {
      @include link-colors($black, $pink, $pink, $black, $black);

      .th-dark & {
        @include link-colors($white, $orange, $orange, $white, $white);
      }
    }

    .bouton a {
      background-color: $pink;

      .th-dark & {
        background-color: $orange;
        color: $black !important;
      }

      &:focus {
        background-color: darken($pink, 10%);

        .th-dark & {
          background-color: darken($orange, 10%);
        }
      }
    }
  }

  &--footer {
    display: none;

    @include desktop {
      display: block;
    }

    a {
      @include link-colors($white, $blue, $blue, $white, $white);

      .th-dark & {
        @include link-colors($black, $white, $white, $black, $black);
      }
    }

    .bouton a {
      background-color: $white;
      color: $turquoise !important;

      .th-dark & {
        background-color: $black;
        color: $turquoise !important;
      }
    }
  }
}
