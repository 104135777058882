.comment {

  &__meta {
    margin-bottom: 12px;
  }

  &__meta-icon {
    display: inline-block;
    margin-right: 7px;
  }

  &__author {
    display: inline-block;

    strong {
      font-weight: 500;
    }
  }

  &__time {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 500;
    color: $grey;
    margin-left: 30px;
  }

  &__children {
    padding: 0;
    margin: 0 0 0 100px;
    list-style: none;
  }
}
