hr {
  display: block;
  width: 100%;
  height: 1px;
  background: $black;
  border: none;
  margin: 1.5em 0;

  .th-dark & {
    background: $white;
  }
}
