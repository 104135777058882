.teaser-post {

  @include tablet {
    display: flex;
    justify-content: space-between;
  }

  @include desktop {
    align-items: flex-end;
  }

  a {
    text-decoration: none;
  }

  &__thumbnail {
    margin-bottom: 30px;

    @include tablet {
      width: span(2);
      margin-bottom: 0;
    }

    @include desktop {
      width: span(4);
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__content {

    @include tablet {
      width: span(4);
    }

    @include desktop {
      width: span(8);
    }
  }

  &__title {
    a {
      color: $black !important;

      .th-dark & {
        color: $white !important;
      }
    }
  }

  &__date,
  &__author {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__author {
    margin-bottom: 20px;
  }

  &__link {
    color: $pink !important;
    font-weight: 500;

    .th-dark & {
      color: $orange !important;
    }
  }
}
