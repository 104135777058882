.single-nav {
  border-top: 1px solid $separator;

  .th-dark & {
    border-color: $grey;
  }

  &__wrapper {
    display: flex;
  }

  &__link {
    text-decoration: none;
    color: $black !important;
    transition: all .3s;
    text-align: center;
    width: 100%;
    padding: 30px;

    .th-dark & {
      color: $white !important;
    }

    @include desktop {
      padding: 50px 120px;
    }

    &--half {
      width: 50%;
      text-align: left;
    }

    &--next {
      order: 1;
    }
  }

  &__link-label {
    color: $pink;
    font-size: 1.6rem;
    font-weight: 500;

    .th-dark & {
      color: $orange;
    }
  }

  &__link-title {
    margin-bottom: 0;
  }

  .single-nav__link--next.single-nav__link--half {
    border-left: 1px solid $separator;

    .th-dark & {
      border-color: $grey;
    }
  }
  .single-nav__link--prev.single-nav__link--half {
    text-align: right;
  }
}
