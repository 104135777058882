.comment-form {

  &__label-reply {
    display: none;
  }
}

.comment-form--reply {
  .comment-form__label {
    display: none;
  }
  .comment-form__label-reply {
    display: inline;
  }
}
