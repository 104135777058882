.share-links {

  &__title {
    margin-bottom: 20px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    margin: 0 15px;
  }

  svg path {

    .th-dark & {
      fill: $turquoise;
    }
  }
}
