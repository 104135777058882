.site-header {
  padding: 20px 0;

  @include tablet {
    padding: 30px 0;
  }

  &__logo {
    display: flex;
    align-items: center;

    @include tablet {
      min-height: 160px;
    }

    img {
      display: block;
      max-width: 160px;
      height: auto;
      margin: 0 auto;

      @include tablet {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }
    }

    @include tablet {
      width: span(2);
    }

    @include desktop {
      width: span(3);
    }
  }

  &__nav {

    @include desktop {
      width: span(9);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 0;
    }

    .social-nav {
      margin-left: 30px;
    }
  }

  &__toggle {

    @include desktop {
      display: none;
    }
  }
}
