.collection {

  &__introduction {
    margin-bottom: 50px;
  }

  &__items {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .card {
    margin-bottom: 40px;
    width: 100%;

    @include tablet {
      width: span(2);
      margin-right: gutter();

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @include desktop {
      width: span(4);
      margin-right: gutter();

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
