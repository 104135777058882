@import "~susy/sass/susy";

// Container external gap.
$gap: 20px;
$gap--tablet: 30px;
$gap--desktop: 20px;

// Overwrite BULMA initial vars (if needed)

$tablet: 704px + (2 * $gap);
$desktop: 1160px + (2 * $gap--desktop);

$susy: (
  'columns': susy-repeat(2),
  'gutters': 20/80,
  'spread': 'narrow',
  'container-spread': 'narrow',
);

$medium: (
  'columns': susy-repeat(6),
  'gutters': 20/60,
  'spread': 'narrow',
  'container-spread': 'narrow',
  'min-width': $tablet
);

$large: (
  'columns': susy-repeat(12),
  'gutters': 40/60,
  'spread': 'narrow',
  'container-spread': 'narrow',
  'min-width': $desktop
);
